/*jslint browser: true*/
/*eslint-env browser*/
/*global jQuery*/

jQuery(document).ready(function ($) {
	'use strict';
	
	$(document).foundation();

	// slider
	$('.home .entry-header .owl-carousel').owlCarousel(
		{
			loop: true,
			autoplay: true,
			smartSpeed: 500,
			autoplayTimeout: 3000,
			autoplayHoverPause: true,
			nav: false,
			dots: false,
			//navText: ["<i class='fal fa-chevron-left' aria-hidden='true'></i>","<i class='fal fa-chevron-right' aria-hidden='true'></i>"],
			//animateOut: 'fadeOut',
			//animateIn: 'fadeIn',
			items: 1,
			/* responsive: {
				0: {
					items: 1,
					//stagePadding: 40,
					//margin: 30
				},
				640: {
					items: 1,
					//stagePadding: 50,
					//margin: 50
				},
				1024: {
					items: 1,
					//stagePadding: 50,
					//margin: 80
				}
			} */
		}
	);
	$('.home .velemenyek .owl-carousel').owlCarousel(
		{
			loop: true,
			autoplay: true,
			smartSpeed: 500,
			autoplayTimeout: 3000,
			autoplayHoverPause: true,
			nav: true,
			dots: false,
			navText: ["<i class='fal fa-chevron-left' aria-hidden='true'></i>","<i class='fal fa-chevron-right' aria-hidden='true'></i>"],
			//animateOut: 'fadeOut',
			//animateIn: 'fadeIn',
			//items: 1,
			responsive: {
				0: {
					items: 1,
					nav: false
				},
				640: {
					items: 1,
					nav: false
				},
				1024: {
					items: 1,
					nav: true
				}
			}
		}
	);

	$('.single-post .entry-footer .owl-carousel').owlCarousel(
		{
			loop: true,
			autoplay: true,
			smartSpeed: 500,
			autoplayTimeout: 3000,
			autoplayHoverPause: true,
			//nav: true,
			dots: false,
			navText: ["<i class='fal fa-chevron-left' aria-hidden='true'></i>","<i class='fal fa-chevron-right' aria-hidden='true'></i>"],
			//animateOut: 'fadeOut',
			//animateIn: 'fadeIn',
			//items: 1,
			responsive: {
				0: {
					items: 1,
					nav: false
					//stagePadding: 40,
					//margin: 30
				},
				640: {
					items: 2,
					nav: false
					//stagePadding: 50,
					//margin: 50
				},
				1024: {
					items: 2,
					nav: true
					//stagePadding: 50,
					//margin: 80
				}
			}
		}
	);
	
	// gomb felirat csere klikkre
	/*$('.korvonal input[type="submit"]').on('click', function(){
		$(this).attr('value', 'Feldolgozás...');
		setTimeout(function(){
			$('.korvonal input[type="submit"]').attr('value', 'Elküld');
		  }, 1000);
	});*/


	// kereses gomb
	$('i.fa-search').click( function() {
		$('#kereses').toggle();
	});


	// wrap youtube iframe with div
	//$( '.single-post .entry-content iframe' ).wrap( '<div class="embed-youtube">' );


	// scroll up button
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('#scrollup').fadeIn();
		} else {
			$('#scrollup').fadeOut();
		}
	});
	$('#scrollup').click(function () {
		$('html, body').animate({
			scrollTop: 0
		}, 400);
		return false;
	});

	// menüből kapcsolathoz footerben
	//$('#menu-item-65').click(function () {
	/* $('a[href*=\\#]').click(function () {
		var $link = $(this).attr("href");
		//console.log($link);
		$('html, body').animate({
			scrollTop: $($link).offset().top
		}, 600);
		if ($(window).width() < 1201) {
			$('.top-bar').css('display','none');
		}
		return false;
	}); */

	// ha a menüben van horgony
	if ($(window).width() < 1201) {
		$('a[href*=\\#]').on('click', function (event) {
			$('.top-bar').css('display','none');
		});
	}

	// form submit csere
	$('p.mezo input[type="submit"]').val('>');

	// aos init
	AOS.init();



});